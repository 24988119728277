@use '../abstracts/mixins/state';
@use '../abstracts/functions';

// State
// --------------------
// Utility classes for setting styles based on component state.
// Classes are prepended with `is-` or `has-`.

.has-open-menu {
  @include state.has-scroll-lock;
}
