@use "./functions";
@import "https://use.typekit.net/evq6bhw.css";

// @font-face-specific mixins
// -----
@mixin embedFont(
  $font-name,
  $font-filepath-and-name,
  $font-weight,
  $font-style
) {
  @font-face {
    font-family: $font-name;
    font-style: $font-style;
    font-weight: $font-weight;
    src: url("../fonts/#{$font-filepath-and-name}.woff2") format("woff2"),
      url("../fonts/#{$font-filepath-and-name}.woff") format("woff");
  }
}

// @font-face declarations
// --------------------------
// Do your @font-face embedding here, in the privacy of your own partial
// `embedFont` must be used once per combination of weight and style

@include embedFont(
  "Muli",
  "muli-v20-latin-300",
  functions.font-weight(light),
  normal
);
@include embedFont(
  "Muli",
  "muli-v20-latin-300italic",
  functions.font-weight(light),
  italic
);
@include embedFont(
  "Muli",
  "muli-v20-latin-600",
  functions.font-weight(semi-bold),
  normal
);
@include embedFont(
  "Muli",
  "muli-v20-latin-700",
  functions.font-weight(bold),
  normal
);
@include embedFont(
  "Muli",
  "muli-v20-latin-700italic",
  functions.font-weight(bold),
  italic
);
