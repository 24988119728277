@use 'abstracts/mixins/base';
@use 'abstracts/functions';

.m-tease {
  @include base.fluid-scale(
    padding-top padding-bottom,
    functions.padding(medium),
    functions.padding(small)
  );

  border-top: 1px solid functions.palette(neutral-10);
}
