@use 'abstracts/functions';
@use 'abstracts/variables';
@use 'abstracts/mixins/typography';
@use 'abstracts/mixins/base';
@use 'abstracts/mixins/appearance';

$nav-break: functions.break(110);
$header-height-tablet: calc(
  var(--header-height, 84px) + var(--admin-bar-height, 0px) + -1px
);
$header-height-mobile: calc(var(--header-height, 84px) + -1px);

.m-nav-desktop {
  @include base.respond($nav-break) {
    display: none;
  }
}

.m-nav-mobile {
  @include base.respond($nav-break, min) {
    display: none;
  }

  @include base.respond($nav-break) {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    display: block;
    padding: functions.padding(x-x-small) 0 functions.padding(small) 0;
    background: functions.palette(white);
    transition: transform 0.3s ease-out;
    transform: translateY(-100%);

    &.is-visible {
      display: block;
      transform: translateY($header-height-tablet);

      @include base.respond(functions.break(60)) {
        transform: translateY($header-height-mobile);
      }
    }
  }
}

.m-nav {
  width: 100%;

  @include base.respond($nav-break, min) {
    padding-left: functions.padding(small);
  }

  &__list {
    @include typography.list-unstyled;
    
    display: flex;
    align-items: center;

    @include base.respond($nav-break) {
      flex-direction: column;
    }
  }

  &__item {
    margin-top: functions.padding(x-x-small);
    margin-bottom: functions.padding(x-x-small);
    text-align: center;

    @include base.respond($nav-break, min) {
      &--right {
        margin-left: auto;
      }
    }
  }

  &__link,
  &__button {
    white-space: nowrap;
  }

  &__link {
    @include typography.copy-nav;
    @include base.fluid-scale(
      margin-right,
      functions.padding(small),
      functions.padding(x-small),
      functions.break(140),
      $nav-break
    );

    display: inline-block;
    padding-top: 5px;
    color: functions.palette(neutral-70);
    border-bottom-color: transparent;
    border-bottom-width: 3px;

    &:hover {
      border-bottom-width: 3px;
    }

    @include base.respond($nav-break) {
      margin-right: 0;
    }
  }

  &__button {
    @include appearance.primary-button;

    margin-right: functions.padding(x-small);

    @include base.respond($nav-break) {
      margin-right: 0;
    }
  }
}
