@use 'abstracts/mixins/layout';
@use 'abstracts/mixins/typography';
@use 'abstracts/functions';

.m-search-header {
  @include layout.container-wide;

  padding-top: functions.padding(large);
  padding-bottom: functions.padding(small);

  &__text {
    @include typography.heading-tertiary;

    color: functions.palette(neutral-70);
  }
}

.m-search {
  width: 100%;

  &__results {
    padding-top: functions.padding(medium);
  }

  .m-search-bar {
    justify-content: flex-start;
  }
}
