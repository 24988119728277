@use 'abstracts/mixins/layout';
@use 'abstracts/mixins/typography';
@use 'abstracts/mixins/base';
@use 'abstracts/functions';

.m-testimonials {
  + * {
    padding-top: functions.padding(large);
  }

  &__quote-wrapper {
    display: flex;
    flex-wrap: wrap;
    row-gap: 1.5rem;
    column-gap: 1.25rem;
    min-height: 5rem;
    padding: 0;
  }

  &__quote {
    flex: 1 1;

    .m-content & {
      padding: 0;
    }
  }

  &__attribution {
    flex: 1 0 100%;
    color: functions.palette(blue);
  }

  &__picture,
  &__image {
    width: 100px;
    height: 100px;
    border-radius: 100%;
  }

  &__picture {
    flex: 0 0 auto;
    background-color: functions.palette(neutral-10);
  }

  &__image {
    object-fit: cover;
  }

  .flickity-enabled {
    position: relative;
  }

  .flickity-enabled:focus {
    outline: none;
  }

  .flickity-viewport {
    position: relative;
    height: 100%;
    overflow: hidden;
    transition: height 0.5s ease;
  }

  .flickity-slider {
    position: absolute;
    width: 100%;
    height: 100%;
    transition: transform 0.3s ease-out;
  }

  .flickity-cell {
    position: absolute;
    top: 0;
    width: 100%;
  }

  .flickity-page-dots {
    display: flex;
    gap: 0.625rem;
  }

  .flickity-page-dot {
    width: 49px;
    height: 0.75rem;
    padding-top: 0.75rem;
    overflow: hidden;
    cursor: pointer;
    border-bottom: 3px solid functions.palette(neutral-10);

    @include base.transition(border);

    &:hover,
    &[aria-current]:hover {
      border-color: functions.palette(lt-gold);
    }

    /* stylelint-disable no-descending-specificity */
    &[aria-current] {
      border-color: functions.palette(gold);
    }
    /* stylelint-enable no-descending-specificity */
  }
}
