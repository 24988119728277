@use 'abstracts/mixins/base';
@use 'abstracts/functions';

.m-sidebar {
  @include base.fluid-scale(flex-basis, 380px, 250px);

  flex-shrink: 0;
  margin-top: functions.padding(large);

  .m-content--with-sidebar & {
    @include base.respond(functions.break(110), min) {
      max-width: 380px;
      margin-top: 0;
      margin-left: functions.padding(x-x-large);
    }
  }

  &__picture {
    margin-bottom: functions.padding(large);

    @include base.respond(functions.break(110)) {
      display: none;
    }
  }

  &__img {
    border-radius: functions.padding(x-x-small);
  }
}
