@use 'sass:map';

// Variables
// ----------

// Colors
// --------------------------------------------------------
$palette: (
  white: #fff,
  neutral-03: #f8f8f8,
  neutral-05: #f2f2f2,
  neutral-10: #e0e0e0,
  neutral-50: #737373,
  neutral-70: #4f4f4f,
  black: #222,
  blue: #242c69,
  gold: #edb232,
  lt-gold: #f9d999,
  yellow: #f8d48a,
  purple: #8a90af,
  purple-50: #b8bacb,
  turquoise: #1a5c73,
  brown: #804600,
);

// Responsive breakpoints
// --------------------------------------------------------
$breakpoints: (
  140: 1440px,
  130: 1280px,
  110: 1120px,
  80: 768px,
  60: 620px,
);

// Measurements
// --------------------------------------------------------
$container-widths: (
  max: 1440px,
  wide: 1150px,
);
$padding-sizes: (
  wide: 140px,
  x-x-large: 100px,
  x-large: 80px,
  large: 60px,
  medium: 50px,
  small: 25px,
  x-small: 20px,
  x-x-small: 10px,
);
$duration: 0.2s;
$timing: ease;

// Typography
// --------------------------------------------------------
$line-height-base: 1.44;
$font-stack-base: 'Muli', helvetica, arial, sans-serif;
$font-stack-heading: 'farnham-display', georgia, serif;
$font-stack-secondary-heading: 'soleil', $font-stack-base;
$font-weights: (
  light: 300,
  regular: 400,
  semi-bold: 600,
  bold: 700,
);
$font-size: (
  normal: 1rem,
  small: 0.888rem,
);

// Appearance
// --------------------------------------------------------

// Cross-component variables
// --------------------------------------------------------

// Grid
$grid: (
  break-mobile: 110,
  columns: 12,
  gap-desktop: map.get($padding-sizes, medium),
  gap-mobile: map.get($padding-sizes, small),
);
