// Global z-index stack
// -----------------------------------
.m-header,
.m-notification {
  z-index: 100;
}

.m-header__banner {
  z-index: 110;
}

.m-nav-mobile {
  z-index: 105;
}

.m-logo {
  z-index: 130;
}

.has-scroll-lock::after {
  z-index: 99;
}

.m-skip-link {
  z-index: 999;
}
