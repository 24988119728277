@use 'abstracts/mixins/base';
@use 'abstracts/mixins/layout';
@use 'abstracts/mixins/typography';
@use 'abstracts/functions';

.m-subnav {
  @include base.respond(functions.break(60), min) {
    text-align: center;
  }

  &__header {
    @include base.respond(functions.break(60), min) {
      max-width: 324px;
    }

    padding-bottom: functions.padding(x-x-small);
    margin: 0 auto functions.padding(x-x-small);
    line-height: 1em;
    color: functions.palette(blue);
    border-bottom: 1px solid functions.palette(gold);
  }

  &__inner {
    @include layout.container-wide;

    padding-top: functions.padding(large);
    padding-bottom: functions.padding(x-large);
  }

  &__nav-item {
    @include base.respond(functions.break(60), min) {
      display: inline-block;
    }

    padding-right: functions.padding(x-x-small);
    margin-bottom: functions.padding(x-x-small);

    @include typography.copy-primary;
  }

  &__search {
    margin-top: functions.padding(large);
  }
}
