@use 'abstracts/mixins/layout';
@use 'abstracts/functions';

.m-columns {
  $counts: 2, 3;

  @each $count in $counts {
    &--#{$count} {
      @include layout.columns(
        $count,
        functions.padding(medium),
        functions.padding(small)
      );
    }
  }
}
