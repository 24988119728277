@use 'abstracts/mixins/base';
@use 'abstracts/functions';

.m-post-image {
  display: none;

  @include base.respond(functions.break(110)) {
    display: block;
    margin-bottom: functions.padding(medium);
    margin-left: 0;
  }

  &__img {
    margin: 0 auto;
    border-radius: functions.padding(x-x-small);
  }
}
