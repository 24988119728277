@use 'abstracts/mixins/typography';
@use 'abstracts/functions';

.m-callout {
  &:not(:last-child) {
    padding-bottom: functions.padding(large);
  }

  &__header-wrapper {
    display: flex;
    align-items: center;
  }

  &__header {
    padding-bottom: 0;
    line-height: 2rem;
  }

  &__icon {
    margin-right: functions.padding(x-x-small);
    color: functions.palette(purple);
  }

  &__text {
    @include typography.copy-primary;

    margin-top: functions.padding(x-x-small);
  }
}
