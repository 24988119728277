@use 'abstracts/mixins/typography';
@use 'abstracts/functions';

.m-content-rte {
  /* Sets the default padding between blocks in rte */
  > * + * {
    padding-block-start: 1.5rem;
  }

  /* Reduces the padding after headings */
  > h1 + *,
  > h2 + *,
  > h3 + *,
  > h4 + *,
  > h5 + *,
  > h6 + * {
    padding-block-start: 1rem;
  }

  /* Reduces the padding between paragraphs and lists */
  > p + ul,
  > p + ol {
    padding-block-start: 0.75rem;

    + p {
      padding-block-start: 0.75rem;
    }
  }

  /* Default left padding for lists */
  /* stylelint-disable no-descending-specificity */
  ul,
  ol {
    padding-inline-start: 2.25rem;
    margin: 0;
  }
  /* stylelint-enable no-descending-specificity */

  > hr {
    padding: 0;
    margin: 2rem 0 3rem;
    border: 0;
    border-bottom: 1px solid functions.palette(neutral-10);
  }

  figcaption {
    @include typography.copy-secondary;
  }
}
