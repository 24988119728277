@use 'abstracts/functions';
@use 'abstracts/variables';
@use 'abstracts/mixins/layout';
@use 'abstracts/mixins/base';

.m-header {
  position: sticky;
  top: 0;

  @include base.respond(functions.break(60), min) {
    top: var(--admin-bar-height, 0);
  }

  &__banner {
    position: relative;
    background: functions.palette(white);
    border-top: 20px solid functions.palette(neutral-05);
    border-bottom: 1px solid functions.palette(neutral-10);
  }

  &__content {
    @include layout.container-max;
    @include base.fluid-scale(
      padding-left padding-right,
      functions.padding(x-small),
      functions.padding(x-x-small)
    );

    display: flex;
    align-items: center;

    @include base.respond(functions.break(110)) {
      justify-content: space-between;
    }
  }
}
