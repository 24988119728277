@use 'sass:math';
@use 'sass:map';
@use 'abstracts/variables';
@use 'abstracts/functions';
@use 'abstracts/mixins/base';

$columns: map.get(variables.$grid, columns);
$max-width: functions.container-width(max);
$gap-desktop: map.get(variables.$grid, gap-desktop);
$gap-mobile: map.get(variables.$grid, gap-mobile);
$breakpoint-mobile: functions.break(map.get(variables.$grid, break-mobile));
$supports-query: '(grid-auto-columns: min-content)';

.m-grid {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  // Use CSS Grid if supported
  // ---
  // Autoprefixer does not support autoplacement (mostly)
  // so test for a newer grid spec property to limit implementation,
  // with Flexbox as fallback
  // see https://github.com/postcss/autoprefixer#does-autoprefixer-polyfill-grid-layout-for-ie
  @supports (#{$supports-query}) {
    display: grid;
    grid-template-columns: repeat($columns, 1fr);
    gap: $gap-desktop;
  }

  @include base.respond($breakpoint-mobile) {
    @supports (#{$supports-query}) {
      grid-template-columns: 1fr;
      gap: $gap-mobile;
    }
  }

  &__item {
    @include base.respond($breakpoint-mobile) {
      flex-basis: 100%;

      &:not(first-child) {
        margin-top: $gap-mobile;
      }

      @supports (#{$supports-query}) {
        grid-column: span 1;

        /* stylelint-disable-next-line max-nesting-depth */
        &:not(first-child) {
          margin-top: 0;
        }
      }
    }

    @include base.respond($breakpoint-mobile + 1, min) {
      // create class names for different column spans
      // e.g. .m-grid__item--8 { grid-column: span 8 };
      @for $i from 1 through $columns {
        &--#{$i} {
          flex-basis:
            calc(
              #{math.div($i, $columns) * 100%} - #{$gap-desktop * 2}
            );

          /* stylelint-disable-next-line max-nesting-depth */
          @supports (#{$supports-query}) {
            grid-column: span #{$i};
          }
        }
      }
    }
  }
}
