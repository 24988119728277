@use 'abstracts/mixins/layout';
@use 'abstracts/mixins/typography';
@use 'abstracts/mixins/base';
@use 'abstracts/functions';

$button-size: 80px;

.m-cta {
  @include layout.container-wide;

  @include base.respond(functions.break(110)) {
    max-width: 600px;
  }

  @include base.fluid-scale(
    padding-bottom,
    functions.padding(x-large),
    functions.padding(large)
  );

  &__widget {
    padding-bottom: functions.padding(small);
  }

  &__link {
    display: inline-flex;
    border-bottom: none;
  }

  &__icon {
    @include base.transition(background color);

    box-sizing: border-box;
    flex: none;
    width: $button-size;
    height: $button-size;
    padding-top: ($button-size * 0.25) - 2;
    color: functions.palette(gold);
    text-align: center;
    background: functions.palette(blue);
    border-radius: $button-size;
  }

  &__link:hover &__icon {
    color: functions.palette(blue);
    background-color: functions.palette(gold);
  }

  span {
    display: block;
  }

  &__text-wrapper {
    padding-left: functions.padding(x-small);
  }

  &__title {
    @include typography.heading-quaternary;

    color: functions.palette(blue);
  }

  &__text {
    @include typography.copy-primary;

    display: block;
    color: functions.palette(neutral-70);
  }

  .m-hero + & {
    @include base.fluid-scale(
      padding-top,
      functions.padding(x-large),
      functions.padding(large)
    );
  }
}
