@use 'sass:list';
@use '../functions';
@use '../variables';

// Application-Wide Mixins
// -----------------------------------

// Fluid Elements
// --------------------------------------------------------
// Set min and max sizes and breakpoints and let SCSS fluidly scale different properties in-between

// Adapted from https://www.sassmeister.com/gist/7f22e44ace49b5124eec
// Usage: @include fluid-type(font-size, 22px, 16px, break(130), break(50));
@mixin fluid-scale(
  $properties,
  $max-value,
  $min-value,
  $max-vw: functions.break(140),
  $min-vw: functions.break(60)
) {
  @each $property in $properties {
    #{$property}: $max-value;
  }

  @media (max-width: $max-vw) {
    @each $property in $properties {
      #{$property}:
        calc(
          #{$min-value} +
          #{functions.strip-unit($max-value - $min-value)} *
          (100vw - #{$min-vw}) /
          #{functions.strip-unit($max-vw - $min-vw)}
        );
    }
  }

  @media (max-width: $min-vw) {
    @each $property in $properties {
      #{$property}: $min-value;
    }
  }
}

// Media queries
// --------------------------------------------------------
// Respond to viewport size
// $size -> viewport size
// $operator -> 'min' or 'max'
// $aspect -> 'width' or 'height'
@mixin respond($size, $operator: max, $aspect: width) {
  @media all and (#{$operator}-#{$aspect}: $size) {
    @content;
  }
}

// Use default transition values, or pass in new ones
@mixin transition(
  $properties,
  $duration: variables.$duration,
  $timing: variables.$timing
) {
  $transitions: ();

  @each $property in $properties {
    $transitions: list.append(
      $transitions,
      $property $duration $timing,
      'comma'
    );
  }

  transition: $transitions;
}
