@use 'abstracts/functions';
@use 'abstracts/mixins/layout';
@use 'abstracts/mixins/base';
@use 'abstracts/mixins/typography';

$hero-break: functions.break(110);

.m-hero {
  background-color: functions.palette(neutral-03);

  &--plain {
    margin-bottom: -(functions.padding(x-x-large));
    background-color: functions.palette(white);
  }

  &__wrapper {
    @include layout.container-wide;
    @include base.fluid-scale(padding-right, 0, 0);

    @include base.respond($hero-break) {
      display: block;
      padding: 0;
    }

    display: flex;
    flex-direction: row-reverse;
  }

  &__image {
    position: relative;
    flex-grow: 1;
    flex-shrink: 0;
    align-self: stretch;

    &__img {
      object-fit: cover;
    }

    &--arrow {
      @include base.fluid-scale(flex-basis, 650px, 280px);

      background-color: functions.palette(neutral-10);

      @include base.respond($hero-break, min) {
        max-width: 650px;
        clip-path: url('#clip-arrow');
      }
    }

    &--arrow &__img {
      @include base.fluid-scale(
        height,
        370px,
        170px,
        $hero-break,
        functions.break(60)
      );

      @include base.respond($hero-break, min) {
        height: 100%;
        max-height: 600px;
      }

      width: 100%;
    }

    &--circle {
      @include base.respond($hero-break) {
        display: none;
      }

      display: flex;
      align-items: center;
      max-width: 525px;

      &::before {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: functions.padding(large);
        clip-path: url('#clip-arrow');
        content: '';
        background-color: functions.palette(yellow);
      }
    }

    &--circle &__img {
      @include base.fluid-scale(
        margin-right,
        functions.padding(wide),
        functions.padding(x-small)
      );

      position: relative;
      width: 380px;
      height: 380px;
      margin-top: functions.padding(x-x-small);
      margin-bottom: functions.padding(x-x-small);
      border-radius: 50%;
    }
  }

  &__text {
    @include base.fluid-scale(
      padding-top padding-bottom padding-right,
      functions.padding(large),
      functions.padding(medium)
    );
    @include base.fluid-scale(
      padding-left,
      functions.padding(x-x-large),
      functions.padding(x-small),
      $hero-break,
      functions.break(60)
    );

    flex: 1 1 auto;
    align-self: center;
    padding-left: 0;
  }

  &__subtitle {
    color: functions.palette(neutral-70);
  }

  &__body {
    @include typography.copy-primary;

    color: functions.palette(neutral-70);
  }

  &__buttons {
    padding-top: functions.padding(small);

    a {
      margin-right: functions.padding(x-x-small);
    }
  }
}
