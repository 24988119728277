@use 'abstracts/mixins/layout';
@use 'abstracts/mixins/base';
@use 'abstracts/functions';

.m-content {
  @include layout.container-wide;

  padding-bottom: functions.padding(x-large);
  color: functions.palette(neutral-70);

  .m-hero + & {
    padding-top: functions.padding(large);
  }

  &--two-columns .m-content-rte {
    @include layout.columns;
  }

  &--with-sidebar {
    @include base.respond(functions.break(110), min) {
      display: flex;
      justify-content: space-between;
    }
  }

  blockquote {
    padding-top: 0.75rem;
    padding-right: functions.padding(medium);
    padding-left: functions.padding(small);

    cite {
      display: block;
      padding-top: functions.padding(x-x-small);
      font-style: italic;
    }
  }

  blockquote > * + * {
    padding-top: functions.padding(x-small);
  }
}
