@use 'abstracts/mixins/appearance';
@use 'abstracts/mixins/base';

.m-skip-link {
  @include appearance.primary-button;
  @include base.transition(transform);

  position: fixed;
  top: var(--admin-bar-height, 0);
  left: 0;
  transform: translateX(-100%);

  &:focus-visible {
    transform: translateX(0);
  }
}

// Move skip anchor to top of header
.m-skip-link-anchor {
  position: absolute;
  margin-top: #{'calc(var(--header-height, 84px) * -1)'};
  opacity: 0;
}
