@use 'abstracts/mixins/typography';
@use 'abstracts/mixins/appearance';
@use 'abstracts/functions';

.m-details {
  &__summary {
    @include appearance.focus-default;
    @include typography.copy-bold;

    margin-bottom: functions.padding(x-small);

    &:hover {
      cursor: pointer;
    }
  }

  &__content {
    @include typography.copy-primary;

    padding-bottom: functions.padding(x-small);
  }
}
