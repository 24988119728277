@use 'abstracts/functions';
@use 'abstracts/mixins/base';
@use 'abstracts/mixins/appearance';

.m-nav-toggle {
  width: 40px;
  height: 40px;
  overflow: hidden;
  color: functions.palette(purple);

  @include appearance.focus-default;

  @include base.respond(functions.break(110), min) {
    display: none;
  }

  &__close {
    display: none;

    .is-toggled & {
      display: inline-block;
    }
  }

  &.is-toggled &__open {
    display: none;
  }
}
