@use 'abstracts/mixins/base';
@use 'abstracts/functions';

.m-image-text {
  &__inner {
    display: flex;

    @include base.respond(functions.break(60)) {
      display: block;
    }
  }

  .wp-block-image {
    flex: 1 0 30%;
    margin-right: functions.padding(medium);

    @include base.respond(functions.break(60)) {
      margin-right: 0;
    }
  }

  &__column {
    flex: 1 1 auto;
  }
}
