@use '../functions';
@use './base' as base-mixins;
@use '../variables';

// Typography mixins
// -----------------------------------
// These should be limited to `font` and other typographic properties only.

// Headings
// --------------------------------------------------------
@mixin base {
  font-family: variables.$font-stack-base;

  // TODO: Figure out a different breakpoint or switch to regular break at 60
  @include base-mixins.fluid-scale(font-size, 112.5%, 100%, functions.break(60));

  line-height: variables.$line-height-base;
}

@mixin proto-heading {
  font-family: variables.$font-stack-heading;
}

@mixin secondary-heading {
  font-family: variables.$font-stack-secondary-heading;
}

@mixin heading-primary {
  @include proto-heading;

  font-size: 2rem;
  font-weight: functions.font-weight(semi-bold);
}

@mixin heading-secondary {
  @include secondary-heading;

  font-size: 1.333rem;
  font-weight: functions.font-weight(semi-bold);
}

@mixin heading-tertiary {
  @include secondary-heading;

  font-size: 1.125rem;
  font-weight: functions.font-weight(semi-bold);
}

@mixin heading-quaternary {
  @include secondary-heading;

  font-size: functions.font-size(normal);
  font-weight: functions.font-weight(semi-bold);
  text-transform: uppercase;
}

// Copy
// --------------------------------------------------------

@mixin proto-copy {
  font-size: functions.font-size(normal);
  font-weight: functions.font-weight(light);
}

@mixin copy-primary {
  @include proto-copy;
}

@mixin copy-secondary {
  font-size: functions.font-size(small);
  font-weight: functions.font-weight(light);
}

@mixin copy-bold {
  font-weight: functions.font-weight(semi-bold);
}

@mixin copy-nav {
  @include heading-quaternary;

  font-size: functions.font-size(small);
  font-weight: functions.font-weight(semi-bold);
  letter-spacing: 0.1rem;
}

@mixin copy-button {
  @include heading-quaternary;

  font-size: functions.font-size(small);
  font-weight: functions.font-weight(semi-bold);
  text-transform: none;
}

// Links
// --------------------------------------------------------

@mixin link-underlined {
  text-decoration: underline;
}

// Lists
// --------------------------------------------------------
@mixin list-unstyled {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

@mixin list-unordered-primary {
  @include proto-copy;
}

@mixin list-ordered-primary {
  @include proto-copy;
}
