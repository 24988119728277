@use '../variables';
@use '../functions';
@use './typography';
@use './base';

// Appearance mixins
// --------------------
// These should be limited to appearance-related properties only.

@mixin focus-default {
  .js-focus-visible &:focus:not(.focus-visible) {
    outline: 0;
  }

  &.focus-visible {
    outline: functions.palette(gold) auto 4px;
  }
}

// Inputs
// --------------------------------------------------------
input {
  @include focus-default;
}

// Icons
// --------------------------------------------------------
@mixin proto-icon {
  display: inline;
  width: 1em;
  height: 1em;
  vertical-align: middle;
  fill: currentcolor;
}

// Links
// --------------------------------------------------------
@mixin primary-link {
  @include focus-default;
  @include base.transition(border color);

  color: functions.palette(turquoise);
  text-decoration: none;
  border-bottom: 1px solid;

  &:hover {
    color: functions.palette(brown);
    border-bottom-color: functions.palette(gold);
  }
}

// Buttons
// --------------------------------------------------------
@mixin button-unstyled {
  appearance: none;
  padding: 0;
  background: transparent;
  border: 0;
}

@mixin proto-button {
  @include button-unstyled;
  @include focus-default;
  @include typography.copy-button;

  display: inline-block;
  padding: 10px 35px;
  white-space: nowrap;
  border-style: solid;
  border-width: 2px;
  border-radius: 50px;

  &:hover {
    cursor: pointer;
  }
}

@mixin primary-button {
  @include proto-button;
  @include base.transition(background color);

  color: functions.palette(white);
  background: functions.palette(blue);
  border-color: functions.palette(gold);

  &:hover {
    color: functions.palette(blue);
    background: functions.palette(gold);
    border-color: functions.palette(gold);
  }
}

@mixin secondary-button {
  @include proto-button;
  @include base.transition(background color border);

  color: functions.palette(neutral-70);
  background: transparent;
  border-color: functions.palette(purple);

  &:hover {
    color: functions.palette(blue);
    background: functions.palette(gold);
    border-color: functions.palette(gold);
  }

  // Override visited color from Wordpress
  &:visited {
    color: functions.palette(neutral-70);
  }
}
