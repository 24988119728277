@use 'abstracts/functions';
@use 'abstracts/mixins/base';
@use 'abstracts/mixins/typography';

.m-pagination {
  padding-top: functions.padding(small);
  border-top: 1px solid functions.palette(neutral-10);

  &__list {
    @include typography.heading-tertiary;

    display: flex;
    align-content: center;
    justify-content: center;
    color: #{rgba(functions.palette(purple), 0.7)};
  }

  &__item {
    padding-right: functions.padding(x-x-small);
    padding-left: functions.padding(x-x-small);
  }

  &__prev {
    margin-right: functions.padding(small);
  }

  &__next {
    margin-left: functions.padding(small);
  }

  &__icon {
    border: none;

    &:hover {
      @include base.transition(color);

      color: functions.palette(gold);
    }
  }
}
