@use 'abstracts/mixins/typography';
@use 'abstracts/mixins/base';
@use 'abstracts/functions';

.m-tease-news {
  display: flex;

  &:first-child {
    border-top: none;
  }

  @include base.respond(functions.break(60)) {
    flex-direction: column;
  }

  &__image-wrapper {
    flex: 0 0 auto;
    border-bottom: none;

    &:hover {
      opacity: 0.8;
      transition: opacity 0.3s ease-out;
    }
  }

  &__image {
    max-width: 250px;
    height: auto;
    max-height: 188px;
    margin-right: functions.padding(x-small);
    border-radius: functions.padding(x-x-small);
    object-fit: cover;

    @include base.respond(functions.break(60)) {
      width: 350px;
      max-width: 100%;
      max-height: 263px;
      margin-right: auto;
      margin-bottom: #{functions.padding(small) * 0.5};
      margin-left: auto;
    }
  }

  &__body {
    flex: 1 1 auto;
    margin-top: 0;
  }

  &__title {
    color: functions.palette(blue);
  }

  &__pagination-list {
    display: flex;

    .dots {
      border: none;
    }

    > * {
      margin-right: functions.padding(x-small);
    }

    @include base.respond(functions.break(60)) {
      > * {
        margin-right: functions.padding(x-x-small);
      }
    }
  }

  &__pagination:link {
    width: 1.7em;
    height: 1.7em;
    text-align: center;
    border-bottom: none;

    @include base.respond(functions.break(60)) {
      width: 1.6em;
      height: 1.6em;
    }
  }

  &__pagination:hover {
    border: 1px solid functions.palette(gold);
  }

  &__pagination-disabled {
    color: functions.palette(neutral-50);
    text-align: center;
    pointer-events: none;

    &--number {
      width: 1.7em;
      height: 1.7em;
      border: 1px solid functions.palette(neutral-10);

      @include base.respond(functions.break(60)) {
        width: 1.6em;
        height: 1.6em;
      }
    }
  }
}
