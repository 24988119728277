@use 'abstracts/mixins/base';

.m-logo {
  display: flex;
  align-items: flex-start;
  pointer-events: none;
  border-bottom: 0;

  @include base.fluid-scale(margin-top, -10px, -16px);
  @include base.fluid-scale(margin-bottom, -36px, -42px);

  &__text {
    @include base.fluid-scale(width, 205px, 178px);

    margin-top: 30px;
    margin-left: 10px;
  }

  &__ribbon {
    @include base.fluid-scale(width, 93px, 80px);
  }

  &__link {
    text-decoration: none;
    pointer-events: all;
    border: none;
  }
}
