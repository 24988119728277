@use '../functions';
@use './base' as base-mixins;
@use '../variables';

// Layout mixins
// -------------------

// Containers
// --------------------------------------------------------
@mixin proto-container(
  $max-width: functions.container-width(wide),
  $wide-padding: functions.padding(wide),
  $narrow-padding: functions.padding(x-small)
) {
  @include base-mixins.fluid-scale(
    max-width,
    $max-width + 2 * $wide-padding,
    $max-width + 2 * $narrow-padding
  );
  @include base-mixins.fluid-scale(
    padding-right padding-left,
    $wide-padding,
    $narrow-padding
  );

  margin-right: auto;
  margin-left: auto;
}

// Wide Container
@mixin container-wide {
  @include proto-container;
}

// The highest-level container for page content. Sets a max-width and centers its children.
@mixin container-max {
  width: 100%;
  max-width: functions.container-width(max);
  margin-right: auto;
  margin-left: auto;
}

@mixin columns($count: 2, $gap-desktop: functions.padding(x-x-large), $gap-mobile: functions.padding(medium)) {
  @include base-mixins.fluid-scale(
    column-gap,
    $gap-desktop,
    $gap-mobile
  );

  hyphens: auto;
  word-break: break-word;

  @include base-mixins.respond(functions.break(110), min) {
    columns: $count;
    column-width: auto;
  }

  // Avoid breaking divs or wordpress groups
  > * {
    break-inside: avoid;
  }
}
