@use 'abstracts/functions';
@use 'abstracts/mixins/layout';
@use 'abstracts/mixins/base';
@use 'abstracts/mixins/typography';
@use 'abstracts/mixins/appearance';

.m-notification {
  @include base.transition(background-color);

  color: functions.palette(black);
  background-color: functions.palette(gold);
  background-color: var(--notification-background, functions.palette(gold));

  &--is-hidden {
    display: none;
  }

  &__inner {
    @include layout.container-wide;

    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: functions.padding(x-small);
  }

  &__button {
    @include appearance.secondary-button;

    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin-left: functions.padding(x-small);
    border: none;

    &:hover {
      color: functions.palette(white);
    }
  }

  &__link {
    color: functions.palette(blue);

    &:hover {
      color: functions.palette(white);
      border-color: functions.palette(white);
    }
  }

  &__text {
    @include typography.copy-secondary;

    padding: 0;
  }

  &__icon {
    width: 1em;
  }

  &:not(&--is-hidden) + .m-header .m-header__banner {
    @include base.transition(border);

    border-top-color: functions.palette(gold);
    border-top-color: var(--notification-background, functions.palette(gold));
  }
}
