@use 'sass:color';
@use '../functions';
@use '../mixins/base';

// State mixins
// --------------------

// Mixins and classes for setting styles based on component state.
// Classes are prepended with `is-` or `has-`.

@mixin has-scroll-lock {
  position: absolute;
  top: 0;
  width: 100%;
  overflow: hidden;

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    background-color: #{rgba(functions.palette(black), 0.75)};
    transition: background-color 0.2s linear;
  }
}
