@use 'abstracts/mixins/base';
@use 'abstracts/mixins/layout';
@use 'abstracts/mixins/typography';
@use 'abstracts/functions';

.m-footer {
  text-align: center;

  &__inner {
    @include layout.container-wide;
    @include typography.copy-secondary;

    padding-top: functions.padding(medium);
    padding-bottom: functions.padding(medium);
  }

  &__nav-item {
    display: inline-block;
    padding: 0 functions.padding(x-x-small);
    margin-bottom: functions.padding(x-x-small);
  }

  &__nav-link {
    color: functions.palette(neutral-70);

    &:hover {
      color: functions.palette(brown);
    }
  }

  &__external-links {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    padding-top: functions.padding(small);
  }

  &__social {
    flex: 0 0 auto;
  }

  &__social-icon {
    width: 50px;
  }

  &__social-item {
    display: inline-block;
    padding-right: functions.padding(x-x-small);
    padding-bottom: functions.padding(small);
    padding-left: functions.padding(x-x-small);
  }

  &__social-link {
    border-bottom: none;
  }

  &__bbb {
    padding-right: functions.padding(x-x-small) + 2px;
    padding-bottom: functions.padding(small);
    padding-left: functions.padding(x-x-small) + 2px;
    margin-top: 2px;
    text-align: center;
  }

  &__bbb-link {
    display: inline-block;
    text-decoration: none;
    border-bottom: 0;
  }

  &__copyright {
    padding-bottom: 0;
    color: functions.palette(neutral-70);
  }
}
