@use '../abstracts/variables';
@use '../abstracts/mixins/appearance';

// Appearance
// --------------------

// Appearance mixins contain primarily texture parameters: background-color, transparency, borders, etc.

// These should be limited to appearance-related properties only.

// Visibility
// --------------------------------------------------------
.a-pos-rel {
  position: relative;
}

.a-pos-abs {
  position: absolute;
}

.a-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  border: 0;
}

.a-hide-overflow {
  overflow: hidden;
}

.a-button,
.is-style-button a {
  @include appearance.primary-button;
}

.a-secondary-button,
.is-style-button-secondary a {
  @include appearance.secondary-button;
}

// Color
// --------------------------------------------------------
// Create a `.a-color-` class for each color in $palette map
@each $name, $value in variables.$palette {
  .a-color-#{"" + $name} {
    color: $value;
    fill: currentcolor;
  }
}

// Create a `.a-bg-` class for each color in $palette map
@each $name, $value in variables.$palette {
  .a-bg-#{"" + $name} {
    background-color: $value;
  }
}

svg {
  fill: currentcolor;
}
