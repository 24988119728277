@use '../abstracts/variables';
@use '../abstracts/functions';
@use '../abstracts/mixins/typography';

// Typography
// --------------------------------------------------------

html {
  @include typography.base;
}

// Block-level elements
// --------------------------
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

h1 {
  @include typography.heading-primary;

  color: functions.palette(blue);
}

h2 {
  @include typography.heading-secondary;

  color: functions.palette(blue);
}

h3 {
  @include typography.heading-tertiary;
}

h4 {
  @include typography.heading-quaternary;
}

h5 {
  @include typography.secondary-heading;

  font-size: functions.font-size(normal);
  font-weight: functions.font-weight(semi-bold);
}

h6 {
  @include typography.secondary-heading;

  font-size: functions.font-size(small);
  font-weight: functions.font-weight(regular);
}

p {
  @include typography.copy-primary;

  margin: 0;
}

ul {
  @include typography.list-unordered-primary;
}

ol {
  @include typography.list-ordered-primary;
}

strong,
b {
  @include typography.copy-bold;
}

.has-normal-font-size {
  @include typography.copy-primary;
}

.has-small-font-size {
  @include typography.copy-secondary;
}
