@use 'abstracts/mixins/layout';
@use 'abstracts/mixins/typography';
@use 'abstracts/functions';

.m-search-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  &__icon {
    margin-right: functions.padding(x-x-small);
    margin-bottom: 5px;
    fill: functions.palette(purple);
  }

  &__input {
    @include typography.copy-primary;

    display: block;
    width: 100%;
    max-width: 474px;
    padding-bottom: 5px;
    background: inherit;
    border: none;
    border-bottom: 1px solid functions.palette(neutral-10);

    &::placeholder {
      @include typography.copy-nav;
    }
  }
}
